export default {
  integrations: {
    list: {
      empty_header: 'Empty token list',
      title: 'Api keys',
      table: {
        head: {
          fields: {
            secret: 'secret',
            key: 'key',
            auth_token: 'auth token',
            action: 'action',
          },
        },
      },
      btn_create: 'Add Token',
    },
  },
  documents: {
    title: 'Client Documents',
    types: {
      contracts: {
        title: 'Client Contracts',
        'dropzone-label': 'Drop Contract files here to upload',
      },
      additional: {
        title: 'Additional Documents',
        'dropzone-label': 'Drop Additional files here to upload',
      },
    },
  },
  fields: {
    name: {
      label: 'Full Name',
      placeholder: 'Your Full Name...',
    },
    email: {
      label: 'E-mail Address',
      placeholder: 'Your E-mail Address...',
    },
    username: {
      label: 'Username',
      placeholder: 'Your Username...',
    },
    emails: {
      label: '',
      placeholder: 'example@email.com',
    },
    phone: {
      label: 'Phone Number',
      placeholder: 'Your Phone Number...',
    },
    dba: {
      label: 'Doing business as',
      placeholder: 'Business Name...',
    },
    business_name: {
      label: 'Business Name',
      placeholder: 'Your Business Name...',
    },
    address: {
      label: 'Main Address',
    },
    legal_address: {
      label: 'Legal Address',
    },
    max_idle_time: {
      label: 'Screen lock interval',
    },
    feedback_google: {
      label: 'Feedback google url',
      placeholder: '',
    },
    orders_fields_show_rx_label: {
      label: 'Show rx on Label',
      placeholder: '',
    },
    allow_hide_label_logo: {
      label: 'Hide our logo on Label',
      placeholder: '',
    },
    allow_feedbacks: {
      label: 'Allow Feedbacks on tracking service',
      placeholder: '',
    },
    restrict_reschedule: {
      label: 'Restrict reschedule for patients',
      placeholder: '',
    },
    allow_change_delivery_time: {
      label: 'Allow patients to set the Time Window order type when rescheduling',
      placeholder: '',
    },
    tracking_while_label: {
      label: 'Tracking while label',
      placeholder: '',
    },
    allow_documents: {
      label: 'Enable Documents on order',
      placeholder: '',
    },
    allow_tips: {
      label: 'Allow TIPS on tracking service',
      placeholder: '',
    },
    facility: {
      label: 'User facility',
      placeholder: '',
    },
    telegram_username: {
      label: 'Telegram Username (without @)',
      placeholder: '',
    },
    skip_check_time_window: {
      label: 'Skip checking Time WIndow',
      placeholder: '',
    },
    all_documents: {
      label: 'All Documents on order',
      placeholder: '',
    },
    skip_weekend: {
      label: 'Monday is default if creating order on Friday',
      placeholder: '',
    },
    delivery_approve_type: {
      label: 'Default Delivery Method',
      placeholder: '',
    },
    default_order_type: {
      label: 'Default Order Type',
      placeholder: '',
    },
    default_call_upon_arrival: {
      label: 'Create order with enabled "Call upon arrival"',
      placeholder: '',
    },
    copay_is_paid_by_default: {
      label: 'Copay is paid by default',
      placeholder: '',
    },
    soft_search: {
      label: 'Soft Search',
      placeholder: '',
    },
    orders_fields_rx_number: {
      label: 'RX Number',
      placeholder: '',
    },
    pickup_time_bypass: {
      label: 'Bypass Pickup Date/Time',
      placeholder: '',
    },
    orders_fields_date_filled: {
      label: 'Date Filled',
      placeholder: '',
    },
    orders_fields_operator_initials: {
      label: 'Operator Initials',
      placeholder: '',
    },
    orders_fields_operator_initials_required: {
      label: 'Operator Initials is required?',
      placeholder: '',
    },
    orders_fields_operator_name: {
      label: 'Technician`s Initials',
      placeholder: '',
    },
    orders_fields_operator_name_required: {
      label: 'Technician`s Initials is required?',
      placeholder: '',
    },
    orders_receipt_type: {
      label: 'Order Label Template',
      placeholder: '',
    },
    label_scale: {
      label: 'Order Label Scale',
      placeholder: '',
    },
    label_template_pdf: {
      label: 'Order Label Template PDF',
      placeholder: '',
    },
    label_scale_pdf: {
      label: 'Order Label Scale PDF',
      placeholder: '',
    },
    orders_fields_webhook_enabled: {
      label: 'Web Hook Enabled',
    },
    orders_fields_webhook_url: {
      label: 'Web Hook Url',
      placeholder: '',
    },
    orders_fields_webhook_header_data: {
      label: 'Web Hook Header Parameters',
    },
    orders_fields_webhook_mode: {
      label: 'Web Hook Mode',
      placeholder: '',
    },
    orders_fields_webhook_oauth_url: {
      label: 'Web Hook OAuth Url',
      placeholder: '',
    },
    orders_fields_webhook_oauth_data: {
      label: 'Web Hook OAuth Parameters',
      placeholder: '',
    },
    forward_email: {
      label: 'Forward to email',
      placeholder: 'Forward email',
    },
    bank_account_name: {
      label: 'Bank Account Name',
      placeholder: 'Your Bank Account Name...',
    },
    bank_account_number: {
      label: 'Bank Account Number',
      placeholder: 'Your Bank Account Number...',
    },
    bank_account_route_number: {
      label: 'Bank Account Route Number',
      placeholder: 'Your Bank Account Route Number...',
    },
    bank_account_corporation_name: {
      label: 'Corporation Name',
      placeholder: 'Your Corporation Name...',
    },
    bank_account_tax_id: {
      label: 'Corporation TAX ID (EIN)',
      placeholder: 'Your Corporation TAX ID (EIN)...',
    },
    forward_orders_ready_for_pickup: {
      label: 'Forward Ready for Pickup',
      placeholder: '',
    },
    forward_orders_driver_out: {
      label: 'Forward Driver Out',
      placeholder: '',
    },
    forward_orders_delivered: {
      label: 'Forward Delivered',
      placeholder: '',
    },
    forward_orders_delivery_attempted: {
      label: 'Forward Delivery Attempted',
      placeholder: '',
    },
    forward_orders_ready_to_print: {
      label: 'Forward Ready to Print',
      placeholder: '',
    },
    forward_orders_reschedule_pt: {
      label: 'Forward Patient Reschedule',
      placeholder: '',
    },
    forward_orders_reschedule_dsp: {
      label: 'Forward Dispatcher Reschedule',
      placeholder: '',
    },
    forward_orders_change_address: {
      label: 'Forward Change Address',
      placeholder: '',
    },
    dob: {
      label: 'Date of birth',
      placeholder: 'Date of birth',
    },
    position: {
      label: 'Position',
      placeholder: 'Work position',
    },
    hired: {
      label: 'Hired date',
      placeholder: 'Date',
    },
    tax_id: {
      label: 'Tax ID',
      placeholder: 'Tax ID',
    },
    ssn: {
      label: 'SSN',
      placeholder: 'SSN',
    },
    type: {
      label: 'User Type',
      placeholder: 'User Type',
    },
    allow_see_recipients_phones: {
      label: 'Allow Driver to See Recipients Phone Number',
    },
    allow_copypaste_names: {
      label: 'Allow Driver to Copypaste Recipients Name',
    },
    allow_use_react_native_app: {
      label: 'Allow Driver to use React Native (old) app',
    },
    type_planner_access: {
      label: 'Does this user have any access to the Route Planner`s All',
    },
    mark_facemask_interval: {
      label: 'FACEMASK Interval for the Driver',
    },
    call_center_ext: {
      label: '3 Digit Call Center Extension',
      placeholder: '3 Digit Call Center Extension',
    },
    wage: {
      label: 'Wage',
      placeholder: 'Wage $...',
    },
    driverPro: {
      label: 'Pro Driver',
    },
    disallow_old_driver_app: {
      label: 'Disallow old Driver-App',
    },
    rate: {
      label: 'Default order Rate',
    },
    rate_type: {
      label: 'Order Rate type',
    },
    bypass: {
      label: 'Credit Card Bypass',
    },
    clients_access: {
      label: 'Manage clients',
      placeholder: 'Can Manage clients',
    },
    hire_type: {
      label: 'Hire Type',
    },
    driverLevel: {
      label: 'Driver Level',
    },
    create_ticket_after_24h: {
      label: 'Create ticket if fridge item was more than 24h',
    },
    orders_fields_rid: {
      label: 'Remote Order ID',
    },
    free_return_to_pharmacy: {
      label: 'Free Return to Pharmacy',
    },
    free_pharmacy_pickups: {
      label: 'Free Pharmacy Pickups',
    },
    dnc_patient: {
      label: 'Do Not Call Patients',
    },
    forward_all_orders_to_client_mail: {
      label: 'Checked by Default - Forward All Orders',
    },
    // allow_request_delivery_time: {
    //   label: 'Allow to request delivery timewindow from patient?',
    // },
    send_sms: {
      label: 'Send SMS to clients',
    },
    send_voice: {
      label: 'Send Voice Messages to clients',
    },
    npi: {
      label: 'Pharmacy NPI',
    },
    payment_period: {
      label: 'Payment Period',
    },
    mark_invoice_interval: {
      label: 'Invoice Due Days',
    },
    mark_invoice_overdue_percent: {
      label: 'Invoice Overdue %',
    },
    mark_invoice_overdue_interval: {
      label: 'Invoice Overdue Interval',
    },
    copay_limit: {
      label: 'Copay limit',
      placeholder: '',
    },
    attempts_limit: {
      label: 'Attempts limit',
      placeholder: '',
    },
    special_instructions: {
      label: 'Special Instructions',
      placeholder: '',
    },
    top_client: {
      label: 'Top Client',
    },
    tracking_style_theme: {
      label: 'Tracking style theme',
    },
    return_to_parmacy_period: {
      label: 'Time period for Return to Pharmacy',
    },
    show_phone_on_label: {
      label: 'Show phones on labels',
    },
    maximum_copay: {
      label: 'Maximum copay',
    },
    allow_sunday: {
      label: 'Allow Delivery on Sunday',
    },
    allow_orders_anywhere: {
      label: 'Allow to create orders anywhere',
    },
    client_method: {
      label: 'Customize clients Method Orders',
    },
    client_facility: {
      label: 'Customize clients Facility Orders',
    },
    default_not_paying_copay_action: {
      label: 'Default paying copay action',
    },
    min_driver_photo_delivery: {
      label: 'Minimum required pictures Delivery',
    },
    allow_draft_we_do_not_delivery: {
      label: 'Allow Create Draft order if We Do not Delivery',
    },
    min_driver_photo_apt: {
      label: 'Minimum required pictures Apt',
    },
    min_driver_photo_fridge: {
      label: 'Minimum required pictures Fridge',
    },
    min_driver_photo_freezer: {
      label: 'Minimum required pictures Freezer',
    },
    min_driver_photo_id: {
      label: 'Minimum required pictures Id',
    },
    min_driver_photo_copay: {
      label: 'Minimum required pictures Copay',
    },
    min_driver_photo_package: {
      label: 'Minimum required pictures Package',
    },

    pioneerrx_confirmation_type: {
      label: 'Confirmation type to PioneerRx',
    },
    pioneerrx_allow_resync: {
      label: 'Allow resyncronize for PioneerRx',
    },
    prx_single_order_by_recipient_per_day: {
      label: 'PrimeRX Single order by recipient per day',
    },
    prime_rx_track_link: {
      label: 'Choose PrimeRX tracking link',
    },
    prime_rx_v2_name_format: {
      label: 'PrimeRX V2 Name Format',
    },
    // prime_rx_carrier_rx2go: {
    //   label: 'Can use PrimeRX carrier RX2GO',
    // },
    is_disable_my_invoices: {
      label: 'Disable "My invoices" page',
    },
    stipe_commission_on_us: {
      label: 'Stripe Commission On Us',
    },
    not_use_route_price: {
      label: 'Not Use/Demand Route Price',
    },
    call_on_reschedule: {
      label: 'Call to reschedule the delivery',
    },
    hide_insurence: {
      label: 'Hide Include insurance switch',
    },
    insurance_rate: {
      label: 'Insurance rate (ex 0.0075)',
    },
    maximum_insurance: {
      label: 'Maximum insurance',
    },
    hide_date_for_pickup: {
      label: 'Hide pickup date for new order',
    },
    save_method_to_abook: {
      label: 'Save patient\'s delivery type into Address Book',
    },
    hide_carrier_for_new_order: {
      label: 'Hide carrier select for new order page',
    },
    invoice_po: {
      label: 'Invoice PO#',
      placeholder: 'Invoice PO#',
    },
    override_delivery_type_from_history: {
      label: 'Not apply delivery type on suggest user',
    },
    single_order_by_recipient_per_day: {
      label: 'Single order by recipient per day',
    },
    allow_use_assign_routes: {
      label: 'Turn ON Assign Routes',
    },
    order_copay_check_enabled: {
      label: 'Copay option Check',
    },
    order_copay_cash_enabled: {
      label: 'Copay option Cash',
    },
    order_signature_notification_repeat_days: {
      label: 'repeat days after delivery',
    },
    order_signature_notification_times: {
      label: 'time to send',
      'add-action': 'Add mote',
    },
    order_signature_notification_enabled: {
      label: 'send notificatoins',
    },
    shippo_billing_option: {
      label: 'Shippo billing option',
    },
    shippo_incentive: {
      label: 'Shippo incentive %',
    },
    api_sync_overrige_on_get_to_default: {
      label: 'Override orders params to default for api sync get order',
    },
    override_instruction_not_paid_copay_action: {
      label: 'Override not paid copay action',
    },
    override_instruction_not_paid_copay_action_title: {
      label: 'Overrided not paid copay action field title',
      placeholder: 'Title',
    },
    override_instruction_not_paid_copay_action_value: {
      label: 'Overrided not paid copay action field value',
      placeholder: 'Value',
    },
    filter_shippo: {
      label: 'Filter Shippo By Type',
      placeholder: '',
    },
    filter_shippo_allow_ups: {
      label: 'Allow Shippo UPS',
      placeholder: '',
    },
    filter_shippo_allow_usps: {
      label: 'Allow Shippo USPS',
      placeholder: '',
    },
    filter_shippo_allow_dhl: {
      label: 'Allow Shippo DHL',
      placeholder: '',
    },
    filter_shippo_allow_fedex: {
      label: 'Allow Shippo FedEx',
      placeholder: '',
    },
    filter_carrier_allow_easy_post: {
      label: 'Allow Use EasyPost',
      placeholder: '',
    },
    filter_carrier_allow_shippo: {
      label: 'Allow Use Shippo',
      placeholder: '',
    },
    scan_hide_already_printed_label_warning: {
      label: 'Hide Already Printed Label Warning',
      placeholder: '',
    },
  },
}
